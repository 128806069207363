import React, { useEffect, useState } from "react";
import "./Landing.scss";
import * as Scroll from "react-scroll";
import Arrow from "../../assets/Arrow.svg";
import { Hold } from "../widgets/Hold/Hold";

interface LandingProps {
  headerElement?: Element;
  headerText?: string;
  smallerText?: boolean;
  scrollTo: string;
  scrollOffset: number;
}

export const Landing: React.FC<LandingProps> = ({
  headerElement,
  headerText,
  smallerText,
  scrollTo,
  scrollOffset,
}) => {
  const [scrollTop, setSrollTop] = useState<number>(
    typeof window !== "undefined" ? window.pageYOffset : 0
  );
  const [readMoreVisible, setReadMoreVisible] = useState<boolean>(true);

  const handleScroll = () => {
    setSrollTop(window.pageYOffset);
    if (scrollTop > 50) setReadMoreVisible(false);
    else setReadMoreVisible(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="landing">
      <div className="container">
        {headerElement ? (
          headerElement
        ) : (
          <>
            <h1 className={`text ${smallerText ? "smaller-text" : ""}`}>
              {headerText}
            </h1>
            <Hold text="" />
          </>
        )}
      </div>
      <Scroll.Link
        className="read-more"
        to={scrollTo}
        spy={true}
        smooth={true}
        duration={300}
        offset={scrollOffset}
      >
        {readMoreVisible ? (
          <>
            <div className="read-more__text">Read more</div>
            <Arrow className="read-more__arrow" alt="" />
          </>
        ) : null}
      </Scroll.Link>
    </div>
  );
};
