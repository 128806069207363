import "./Hold.scss";

import React, { useState } from "react";

export interface HoldProps {
  text: string;
}

export const Hold = (props: HoldProps) => {
  return (
    <div className="hold">
      <div className={"hold__shape-container"}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 154 160"
          xmlns="http://www.w3.org/2000/svg"
          className="hold__shape"
        >
          <path
            d="M77 1.773l46.357 14.94 28.65 39.113v48.348l-28.65 39.113L77 158.227l-46.357-14.94-28.65-39.113V55.826l28.65-39.113z"
            fillRule="nonzero"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className="content">{props.text}</div>
    </div>
  );
};
