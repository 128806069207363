import React from "react";
import "./LandingPortfolio.scss";
import Button from "../widgets/Button/Button";
import { Link } from "gatsby";
import Img from "gatsby-image";

interface Project {
  image: string;
  title: string;
  imageStyles?: object;
}

interface LandingPortfolio {
  images: Array<string>;
}

export const LandingPortfolio = ({ images }: LandingPortfolio) => {
  const projects: Project[] = [
    {
      title: "TEDxWarsaw",
      image: images[0],
      imageStyles: { transform: "translateX(20px)" },
    },
    {
      title: "Captio",
      image: images[1],
      imageStyles: { transform: "translateY(-5px)" },
    },
    {
      title: "Medrefer",
      image: images[2],
      imageStyles: { transform: "translateY(-5px)" },
    },
  ];

  return (
    <div className="landing-portfolio-container">
      <h2>Our work</h2>
      <h5>Projects</h5>
      <div className="landing-portfolio">
        {projects.map((project) => (
          <div className="project">
            <Img
              fixed={project.image}
              objectFit="contain"
              alt="Medrefer app"
              imgStyle={{ ...project.imageStyles, objectFit: "contain" }}
            />
            <span>{project.title}</span>
          </div>
        ))}
      </div>
      <Link to={`/portfolio`}>
        <Button text="VISIT PORTFOLIO" />
      </Link>
    </div>
  );
};
