import "./Services.scss";

import AndroidIcon from "../../assets/Android.svg";
import FlutterIcon from "../../assets/Flutter.svg";
import IOsIcon from "../../assets/Ios.svg";
import JamstackIcon from "../../assets/Jamstack.png";
import ParticleShape from "../../assets/particle-shape.svg";
import ReactIcon from "../../assets/React2.svg";

import React from "react";

const standards = [
  {
    name: "NFC",
  },
  {
    name: "HCE",
  },
  {
    name: "bluetooth",
  },
  {
    name: "PCI DSS",
  },
  {
    name: "hipaa",
  },
  {
    name: "iso 27001",
  },
];

export const Services = () => (
  <div id="services" className="container">
    <h2>What we do</h2>
    <h5>Technologies</h5>
    <div className="content">
      <div className="tables-container">
        <div>
          <div className="features-technology">
            <h2 className="features-technology__title_mobile">
              Technologies <br /> that we love and standards we trust
            </h2>
            <div className="features-technology__items">
              <div className="features-technology__items__item features-technology__items__item--grey">
                <img
                  src={JamstackIcon}
                  alt="jamstack icon"
                  width={"40px"}
                  height={"40px"}
                />
                <span className="features-technology__items__item-description features-technology__items__item-description--small-margin">
                  Jamstack
                </span>
              </div>
              <div className="features-technology__items__item features-technology__items__item--full-grey">
                <FlutterIcon alt="flutter icon" />
                <span className="features-technology__items__item-description">
                  Flutter
                </span>
              </div>
              <div className="features-technology__items__item features-technology__items__item--full-grey">
                <ReactIcon style={{ maxHeight: 70 }} alt="react icon" />
                <span className="features-technology__items__item-description">
                  React Native
                </span>
              </div>
              <div className="features-technology__items__item features-technology__items__item--white">
                <ParticleShape alt="" />
              </div>
              <div className="features-technology__items__item features-technology__items__item--full-grey">
                <ReactIcon style={{ maxHeight: 70 }} alt="react js" />
                <span className="features-technology__items__item-description">
                  React JS
                </span>
              </div>
              <div className="features-technology__items__item features-technology__items__item--full-grey">
                <AndroidIcon alt="android icon" />
                <span className="features-technology__items__item-description">
                  Android
                </span>
              </div>
              <div className="features-technology__items__item features-technology__items__item--grey">
                <IOsIcon alt="IOs icon" />
                <span className="features-technology__items__item-description features-technology__items__item-description--big-margin">
                  IOS
                </span>
              </div>
            </div>
            <h2 className="features-technology__title">
              Technologies that we love and standards we trust
            </h2>
          </div>
        </div>
        <div>
          <div className="features-standards">
            {standards.map(({ name }, index) => {
              return (
                <div key={"standard" + index} className="feature-standards">
                  {name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
);
