import "./ServicesSecondScroll.scss";

import Offer1 from "../../assets/offer1.svg";
import Offer2 from "../../assets/offer2.svg";
import Offer3 from "../../assets/offer3.svg";

import React from "react";
import { OurPartners } from "../OurPartners/OurPartners";

export const ServicesSecondScroll = () => (
  <div className="container">
    <div className="header">
      <h5 className="features__title">Services</h5>

      <p className="features__message">
        We offer a complex service, from specialist consulting to practical
        implementation of your business ideas in the production environment.
      </p>
    </div>

    <div className="features">
      <div className="feature">
        <Offer1 alt="Research and analise icon" />
        <div className="feature_header">RESEARCH &amp; ANALISE</div>
        <ul>
          <li>Feasibility study</li>
          <li>Technological consulting</li>
          <li>Designing systems architecture</li>
          <li>Development strategy</li>
          <li>Risk analysis</li>
        </ul>
      </div>

      <div className="feature">
        <Offer2 alt="Design and develop icon" />
        <div className="feature_header">DESIGN &amp; DEVELOP</div>
        <ul>
          <li>User interface design and UX</li>
          <li>Mobile apps</li>
          <li>Web apps</li>
          <li>Existing software development</li>
          <li>Quality assurance</li>
        </ul>
      </div>

      <div className="feature">
        <Offer3 alt="We deliver icon" />
        <div className="feature_header">WE DELIVER</div>
        <ul>
          <li>Publication</li>
          <li>Launch control</li>
          <li>Maintanence</li>
          <li>Documentation</li>
        </ul>
      </div>
    </div>
    <OurPartners />
  </div>
);
