import * as Scroll from "react-scroll";
import React from "react";
import { ContinueReading } from "../components/ContinueReading/ContinueReading";
import { Landing } from "../components/Landing/Landing";
import { Services } from "../components/Services/Services";
import { ServicesSecondScroll } from "../components/ServicesSecondScroll/ServicesSecondScroll";
import { TestimonialSlider } from "../components/TestimonialSlider/TestimonialSlider";
import Seo from "../components/Seo/seo";
import { LandingPortfolio } from "../components/LandingPortfolio/LandingPortfolio";
import { graphql } from "gatsby";
import Img from "gatsby-image";

function AboutPage({ data }) {
  console.log(data);
  return (
    <>
      <Seo title={"About"} />
      <div>
        <div className="sections">
          <Scroll.Element name="landing">
            <section>
              <Landing
                headerElement={
                  <>
                    <h1 className="landing-header red-dot">
                      User - oriented Healthtech <br /> and Fintech apps
                    </h1>
                    <Img
                      fixed={data.heroImage.childImageSharp.fixed}
                      objectFit="contain"
                      alt="Medrefer app"
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </>
                }
                scrollTo="landing-portfolio"
                scrollOffset={
                  typeof window !== "undefined" &&
                  -(window.innerHeight / 4 - 150)
                }
              />
            </section>
          </Scroll.Element>
          <Scroll.Element name="landing-portfolio">
            <section>
              <LandingPortfolio
                images={[
                  data.tedxwarsaw.childImageSharp.fixed,
                  data.captio.childImageSharp.fixed,
                  data.medrefer.childImageSharp.fixed,
                ]}
              />
            </section>
          </Scroll.Element>
          <Scroll.Element name="services">
            <section className="services">
              <Services />
            </section>
            <Scroll.Element name="ServicesSecondScroll">
              <section className="services-second-scroll">
                <ServicesSecondScroll />
              </section>
            </Scroll.Element>
          </Scroll.Element>
          <section className="testimonial-slider">
            <TestimonialSlider page="index" hideHeader hideMobile />
          </section>
          <section>
            <ContinueReading nextSection="How we work" link="how-we-work" />
          </section>
        </div>
      </div>
    </>
  );
}

export default AboutPage;

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "imgs/portfolio/KardiologHeroBlack.png" }
    ) {
      childImageSharp {
        fixed(width: 372, height: 526, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tedxwarsaw: file(
      relativePath: { eq: "imgs/portfolio/TEDxWarsawBlack.png" }
    ) {
      childImageSharp {
        fixed(width: 360, height: 250, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    captio: file(relativePath: { eq: "imgs/portfolio/CaptioBlack.png" }) {
      childImageSharp {
        fixed(width: 320, height: 250, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medrefer: file(relativePath: { eq: "imgs/portfolio/MedreferBlack.png" }) {
      childImageSharp {
        fixed(width: 360, height: 250, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
